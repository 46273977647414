@import "../../scss";

.navbar {
  background: white;
  border-radius: 8px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.list {
  display: flex;
  flex-direction: column;
}

.linkLabel {
  cursor: pointer;
  padding: 4px 0;
  &:hover {
    .link {
      background: $nord6;
    }
  }
  &:active {
    .link {
      background: $nord5;
    }
  }
  &.active {
    .link {
      color: $nord6;
      background: $nord10;
      .icon {
        color: $nord6;
      }
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  padding: 0 12px;
  height: 42px;
  color: $x-nord1;
  transition: 80ms ease-in-out;
  .icon {
    color: $nord3;
  }
}
