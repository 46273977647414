@import "../../scss";

.settings {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: auto;
}

.creditDataBox {
  padding: 30px;
  border-radius: 8px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  .title {
    font-size: 1.5rem;
    font-weight: 400;
    color: $x-nord0;
  }
  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $nord4;
      height: 50px;
      .key {
        color: $x-nord1;
        font-size: 1.125rem;
        font-weight: 400;
      }
      .value {
        color: $nord3;
        font-size: 1.125rem;
        font-weight: 400;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.fields {
  max-width: 1620px;
  margin: 0 auto;
  padding: 40px 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .fieldGroup {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: white;
    border-radius: 8px;
    padding: 20px 18px;
    .fieldList {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 18px;
    }
    .fieldTitle {
      font-size: 1.125rem;
      color: $x-nord1;
      font-weight: 400;
    }
  }
  .footerBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .buttonGroup {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    .info {
      font-size: 0.875rem;
      color: $x-nord1;
      span {
        font-weight: 600;
        font-size: 0.875rem;
      }
    }
  }
}

.result {
  position: sticky;
  top: 40px;
  height: min-content;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .resultBox {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
    padding: 40px 14px;
    background: white;
    .resultBox_title {
      font-size: 1.125rem;
      color: $x-nord1;
      font-weight: 400;
    }
    .resultBox_icon_success {
      color: $nord14;
    }
    .resultBox_icon_error {
      color: $nord11;
    }
    .resultBox_resultText {
      color: $nord2;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }
}
